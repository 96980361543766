<template>
  <div class="page-wrap departure-situation">
    <!-- 表单搜索 start -->
    <div class="form-wrap">
      <div class="seach">
        <div class="inputs">
          <div class="divname">城市仓：</div>
          <el-select
            filterable
            v-model="formData.logistics_id"
            clearable
            placeholder="选择城市仓"
          >
            <el-option
              v-for="item in cityStoreList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">自提点：</div>
          <el-select
            filterable
            v-model="formData.delivery_id"
            clearable
            placeholder="选择自提点"
          >
            <el-option
              v-for="item in selfTakeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="inputs">
          <div class="divname">发车日期：</div>
          <el-date-picker
            v-model="formData.cycle"
            type="date"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
        <div class="inputs">
          <el-select v-model="formData.status" clearable placeholder="提报状态">
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.vehicle_model_name"
            clearable
            placeholder="车型"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="(item, index) in vehicle_model_type_name"
              :key="index"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.vehicle_model_type_name"
            clearable
            placeholder="车类型"
          >
            <el-option
              :label="item"
              :value="item"
              v-for="item in vehicle_model_name_list"
              :key="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.is_merge"
            clearable
            placeholder="是否合车"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-select
            v-model="formData.is_platform_dispatch"
            clearable
            placeholder="是否平台调车"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in whetheroption"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="inputs">
          <el-button
            type="primary"
            icon="el-icon-refresh"
            class="refresh"
            @click="onHandleRefresh"
          ></el-button>
          <el-button @click="onSearch" type="primary" icon="el-icon-search"
            >查询</el-button
          >
          <el-button
            type="warning"
            :disabled="!tableData.length"
            @click="exportHandle"
            icon="el-icon-download"
            >导 出</el-button
          >
        </div>
      </div>
    </div>
    <!-- 表单搜索 end -->

    <!-- 列表 start -->
    <div class="table-wrap">
      <el-table
        :data="tableData"
        height="100%"
        v-loading="loading"
        :border="true"
        :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
      >
        <el-table-column prop="cycle" align="center" label="发车日期">
        </el-table-column>
        <el-table-column
          prop="logistics_name"
          align="center"
          label="提报城市仓"
        >
        </el-table-column>
        <el-table-column prop="delivery_name" align="center" label="自提点">
          <template slot-scope="scope">
            <div v-for="(dItem, dIndex) in scope.row.detail_list" :key="dIndex">
              {{
                dItem.delivery_name_list.length > 0
                  ? dItem.delivery_name_list.join(",")
                  : "-"
              }}
            </div>
            <div v-if="scope.row.detail_list.length == 0">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="car_list" align="center" label="车类型">
          <template slot-scope="scope">
            <div v-for="(dItem, dIndex) in scope.row.detail_list" :key="dIndex">
              {{
                dItem.vehicle_model_type_name
                  ? dItem.vehicle_model_type_name
                  : "-"
              }}
            </div>
            <div v-if="scope.row.detail_list.length == 0">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="car_list" align="center" label="车型">
          <template slot-scope="scope">
            <div v-for="(dItem, dIndex) in scope.row.detail_list" :key="dIndex">
              {{ dItem.vehicle_model_name ? dItem.vehicle_model_name : "-" }}
            </div>
            <div v-if="scope.row.detail_list.length == 0">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="is_depart" align="center" label="是否发车">
          <template slot-scope="scope">
            {{ scope.row.is_depart ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column prop="is_merge" align="center" label="是否合车">
          <template slot-scope="scope">
            <div v-for="(dItem, dIndex) in scope.row.detail_list" :key="dIndex">
              {{ dItem.is_merge ? "是" : "否" }}
            </div>
            <div v-if="scope.row.detail_list.length == 0">-</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="is_platform_dispatch"
          align="center"
          label="是否平台调车"
        >
          <template slot-scope="scope">
            <div v-for="(dItem, dIndex) in scope.row.detail_list" :key="dIndex">
              {{ dItem.is_platform_dispatch ? "是" : "否" }}
            </div>
            <div v-if="scope.row.detail_list.length == 0">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="fleet_name" align="center" label="合车城市仓">
          <template slot-scope="scope">
            <div v-for="(dItem, dIndex) in scope.row.detail_list" :key="dIndex">
              {{
                dItem.logisticst_name_list.length > 0
                  ? dItem.logisticst_name_list.join(",")
                  : "-"
              }}
            </div>
            <div v-if="scope.row.detail_list.length == 0">-</div>
          </template>
        </el-table-column>
        <el-table-column prop="report_at" align="center" label="提报时间">
        </el-table-column>
        <el-table-column
          prop="status"
          align="center"
          label="提报状态"
          min-width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.status ? "是" : "否" }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 列表 end -->

    <!-- 分页 start -->
    <div class="fy">
      <el-pagination
        type="primary"
        background
        @size-change="onHandleSizeChange"
        @current-change="onHandleCurrentChange"
        :current-page="formData.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="formData.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { BASE } from "@/api";

export default {
  name: "vehicle-provision-report",
  components: {},
  data() {
    return {
      loading: false,
      disabledExport: false, // 导出禁用
      cityStoreList: [], // 城市仓列表
      selfTakeList: [], // 自提点列表
      vehicle_model_type_name: [], // 车类型
      vehicle_model_name_list: [], // 车型
      formData: {
        page: 1, //	number	页码
        pageSize: 100, //	number	页大小
        logistics_id: "", //	number	筛选城市仓id
        delivery_id: "", //	number	筛选自提点id
        cycle: "", // 发车日期
        vehicle_model_name: "", // 车型
        vehicle_model_type_name: "", //车类型
        is_platform_dispatch: "", //是否平台调车
        is_merge: "", //是否合车
        status: "", //提报状态
      }, // 表单参数
      whetheroption: [
        {
          id: 0,
          name: "否",
        },
        {
          id: 1,
          name: "是",
        },
      ],
      tableData: [], // 城市仓列表
      total: 0,
    };
  },
  mounted() {
    this.onInitData();
  },
  methods: {
    // 初始化
    onInitData() {
      this.postAjaxDepartReportSelectList();
      this.hqlist();
    },
    // 获取筛选项
    async postAjaxDepartReportSelectList() {
      try {
        const res = await this.$api.logistics.postDepartReportSelectList();
        this.cityStoreList = res.data.logistics_list || [];
        this.selfTakeList = res.data.delivery_list || [];
        this.vehicle_model_name_list = res.data.vehicle_model_name_list || [];
        this.vehicle_model_type_name = res.data.vehicle_model_type_name || [];
      } catch (error) {
        console.log(error, "postDepartReportSelectList");
      }
    },

    async hqlist() {
      this.loading = true;
      try {
        const res = await this.$api.logistics.postDepartReportList(
          this.formData
        );

        this.loading = false;
        this.tableData = res.data.data;
        this.total = res.data.total;
      } catch (error) {
        console.log(error, "postDepartReportList");
      }
    },
    /**
     * 导出
     */
    exportHandle() {
      let url = `${
        BASE.PRO2
      }/logistic/depart/report/export?token=${sessionStorage.getItem("token")}`;
      for (let key in this.formData) {
        if (key != "page" && key != "pageSize") {
          url = url + `&${key}=${this.formData[key]}`;
        }
      }
      window.open(url, "_blank");
    },
    /**
     * 重置表单，并刷新
     */
    onHandleRefresh() {
      Object.assign(this.$data.formData, this.$options.data().formData);
      this.hqlist();
    },
    /**
     * 搜索表单
     */
    onSearch() {
      this.formData.page = 1;
      this.hqlist();
    },
    /**
     * 每页条数发生改变
     */
    onHandleSizeChange(val) {
      this.formData.pageSize = val;
      this.hqlist();
    },
    /**
     * 页码发生改变
     */
    onHandleCurrentChange(val) {
      this.formData.page = val;
      this.hqlist();
    },
  },
};
</script>
<style lang="scss">
.position {
  position: relative;
}
.from-wraps {
  /** 修改element基础样式 */
  .el-select .el-input.is-disabled .el-input__inner {
    color: #333333;
  }

  .el-input.is-disabled .el-input__inner {
    color: #333333;
  }
}
.position-subset {
  position: absolute;
  right: -20%;
  top: 38%;
  .el-icon-circle-plus {
    font-size: 45px;
  }
  .el-icon-delete-solid {
    font-size: 45px;
  }
}
.page-wrap {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  box-sizing: border-box;
  padding: 10px;
  .showImg {
    text-align: center;
    height: 76vh;
  }
  .form-wrap {
    background: #ffffff;
    padding: 13px 15px;
    box-sizing: border-box;
    display: flex;
    .seach {
      flex: 1;
      display: flex;
      //   justify-content: flex-end;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      .inputs {
        display: flex;
        align-items: center;
        .divname {
          width: 100px;
        }
        // width: 207px;
        // margin-right: 8px;
      }
    }
    .refresh {
      background: #333333;
      border-color: #333333;
    }
  }
  .table-wrap {
    flex: 1;
    overflow-y: hidden;
    .text-btn {
      color: $theme-color;
      cursor: pointer;
    }
    .el-button {
      &--text {
        padding: 10px 0;
      }
    }
  }
  .fy {
    box-sizing: border-box;
    padding: 10px 20px;
  }
  .contents {
    padding-left: 55px;
    padding-right: 55px;
    padding-top: 23px;
  }
  .base-margin {
    margin: 0 10px;
    white-space: nowrap;
  }
  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }
}
.imglist {
  display: flex;
  .imglist-left {
    margin-right: 20px;
    font-size: 18px;
  }
  .imglist-right {
    flex: 1;

    .imgs {
      width: 100px;
      height: 100px;
      margin-right: 20px;
    }
    .video {
      width: 200px;
      height: 200px;
      margin-right: 20px;
    }
  }
}
</style>
